.flow-log-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 4px 0px;
}

.flow-log-contact-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
