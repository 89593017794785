.AllCampaignStats {
  display: flex;
  gap: 4px;
}

.CampaignStatCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #09090b;
  /* gap: 4px; */
  /* min-height: 125px; */
}
.CampaignStatCard.interactive {
  cursor: pointer;
}
.CampaignStatCard.interactive:hover {
  background-color: #e5e7eb44;
}
.CampaignStatCard.selected {
  border: 1px solid var(--primary-color);
}

.campaign-stat-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.campaign-stat-card-title-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.campaign-stat-card-content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  /* gap: 6px; */
}

.campaign-stat-card-value {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #000;
}

.campaign-stat-card-extra {
  font-size: 12px;
  color: #71717a;
}

.CampaignData {
  display: flex;
  /* gap: 48px; */
  gap: 16px;
  width: 100%;
  height: 100%;
  /* padding: 24px 48px; */
  padding: 24px 32px;
  box-sizing: border-box;
  overflow-y: auto;
}

.campaign-data-main-container {
  width: 100%;
}
.campaign-data-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.campaign-data-name-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.campaign-data-name {
  font-size: 20px;
  font-weight: 600;
}
.campaign-data-section-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 0;
}
.campaign-data-stat-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
}

.campaign-data-side-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 40vw;
  height: 100%;
  padding-left: 16px;
  border-left: 1px solid #e5e7eb77;
}
.campaign-data-side-title {
  font-size: 18px;
  font-weight: 500;
}
.campaign-data-side-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e7eb77;
}
.campaign-data-side-info.oneColumn {
  grid-template-columns: repeat(1, 1fr);
}
.campaign-data-side-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.CampaignDataSummary {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.campaign-data-summary-label {
  color: #71717a;
}
.campaign-data-summary-value {
  color: #000;
  font-weight: 500;
}

.CampaignDataDonut {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e7eb77;
}
.campaign-data-donut-title {
  font-size: 16px;
  font-weight: 500;
}

.CampaignDataTime {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  height: fit-content;
}
.campaign-data-time-title {
  font-size: 16px;
  font-weight: 500;
}
.campaign-data-time-tooltip {
  padding: 8px;
  border-radius: 8px;
  background-color: #fff;
}
.campaign-data-time-tooltip-title {
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
}
.campaign-data-time-tooltip-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}
.campaign-data-time-tooltip-color {
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
.campaign-data-time-tooltip-label {
  font-size: 12px;
  color: #71717a;
}
.campaign-data-time-tooltip-value {
  display: block;
  margin-left: 8px;
  font-size: 12px;
}

.envio-data-graph {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  height: fit-content;
}

@media (min-width: 1600px) {
  .campaign-data-stat-container {
    grid-template-columns: repeat(6, 1fr);
  }
}
