.tag-estado {
  padding: 2px 8px;
  color: white;
  border-radius: 6px;
}
.aprobado {
  background-color: #77dd77;
}
.pendiente {
  background-color: #f7c935;
}
.rechazado {
  background-color: #ff6961;
}
.tabla-wrapper {
  height: 100%;
  overflow-y: scroll;
}
.contenedor-botones {
  display: flex;
  align-items: center;
  gap: 8px;
}

.create-template-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  width: 100%;
  height: calc(100% - 56px);
  /* overflow-y: auto; */
  background-color: #f0f0f0;
  border-radius: 8px;
  gap: 8px;
}
.template-options-wrapper {
  background-color: white;
  padding: 16px;
  width: calc(100% - 24px);
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
}
.radio-option-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}
.radio-option-description {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: calc(100% - 28px);
}
.radio-option-title {
  font-size: 15px;
  font-weight: 600;
}
.radio-option-caption {
  font-size: 13px;
  word-wrap: break-word;
}
.template-options-wrapper .ant-radio-inner {
  width: 20px !important;
  height: 20px !important;
}
.template-header-name {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  color: #1c1e21;
}
.icono-editar {
  cursor: pointer;
}

.template-header-opciones {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  color: #4a4a4a;
  font-weight: 600;
}
.linea-horizontal-call {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}

.campaign-button-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}

.help-link {
  font-size: 13px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100% - 25px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.row-create-template {
  width: 100%;
  gap: 12px;
  flex-flow: row nowrap;
  justify-content: center;
  max-height: 100%;
}
.columna-datos-template {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  overflow-y: auto;
  height: 100%;
  width: clamp(150px, 70%, 900px);
  border-radius: 8px;
}
.item-columna-datos {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.item-dato-titulo {
  font-weight: 600;
  font-size: 16px;
}
.item-dato-subtitulo {
  font-size: 14px;
  font-weight: normal;
  color: rgba(28, 43, 51, 0.65);
}
.columna-ejemplo-template {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: clamp(100px, 30%, 350px);
  border-radius: 8px;
  padding: 12px;
  background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    url("/public/assets/wsp-background.png");
  max-height: 100%;
  overflow-y: auto;
}
.columna-datos-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 8px;
}
.columna-simple {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.radio-medio-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  position: relative;
}
.radio-medio-item .ant-radio {
  position: absolute;
  left: 6px;
  top: 6px;
}
.radio-media .ant-radio-inner {
  width: 20px !important;
  height: 20px !important;
}
.bubble-place {
  display: flex;
  flex-direction: column;
  width: clamp(100px, 100%, 280px);
  box-sizing: border-box;
}

.bubble {
  min-width: 100px;
  min-height: 25px;
  width: clamp(100px, 100%, 280px);
  background: #f5f5f5;
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  /* border: 1px solid #e8e8e8; */
  position: relative;
  display: flex;
  flex-direction: column;
  /* max-width: 100%; */
  box-sizing: border-box;
  padding: 4px;
}
.fit-wrapper {
  width: calc(100% - 8px);
}
.txt {
  padding: 4px;
  width: calc(100%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-wrap: wrap;
  gap: 8px;
}
.name {
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.76);
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.name span {
  font-weight: normal;
  color: #b3b3b3;
}
.message {
  font-size: 13.6px;
  color: #2b2b2b;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 20px;
}
.footer {
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  line-height: 17px;
  margin-top: 4px;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.timestamp-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.timestamp {
  font-size: 11px;
  position: relative;
  text-transform: uppercase;
  color: #999;
  margin-right: 4px;
}
.document-details {
  font-size: 11px;
  position: relative;
  color: #999;
}
.bubble-arrow {
  position: absolute;
  top: 0px;
  left: -8px;
  border-left: 12px solid transparent;
  border-top: 12px solid #f5f5f5;
  border-top-left-radius: 4px;
}
.bubble-arrow-right {
  position: absolute;
  top: 0px;
  right: -8px;
  border-right: 16px solid transparent;
  border-top: 12px solid #bbd8fb;
  border-top-right-radius: 4px;
}
.layout-botones {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  gap: 2px;
  margin-top: 4px;
}
.layout-botones-all {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.boton-accion-template {
  color: #00a5f4;
  min-width: calc(50% - 2px);
  flex-grow: 1;
  font-size: 14px;
  height: 34px;
  line-height: 20px;
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap;
}
.boton-accion-template span {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
.boton-accion-template:hover {
  border: 1px solid #d9d9d9 !important;
  color: #00a5f4 !important;
  box-shadow: none !important;
}
.preview-title {
  color: #1c1e21;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 18px;
  color: rgb(74, 74, 74);
  font-size: 15px;
  font-weight: 600;
  margin: 8px 0px;
}

.template-crear-boton {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0px;
}
.marketing-button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 12px 0px;
  align-items: center;
}
.contenedor-botones-texto {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  align-self: flex-end;
}
.boton-texto {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 24px;
  width: 28px;
  border-radius: 4px;
}
.boton-texto:hover {
  background-color: #e3e3e3;
}
.columna-tooltip {
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-sizing: border-box;
  gap: 8px;
}
.texto-tooltip {
  word-wrap: break-word;
  color: #1c1e21;
  font-size: 13px;
}
.header-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  width: 100%;
}
.input-text-area::after {
  position: absolute;
  left: 8px;
  bottom: -20px;
}
.media-header-wrapper {
  min-height: 140px;
  width: 100%;
  min-width: 100px;
  border-radius: 8px;
  background-color: #ccd0d5;
  margin-bottom: 3px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.modal-wrapper {
  width: 800px;
}
.layout-muestra {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 8px;
}
.layout-muestra-izq {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.layout-muestra-izq span {
  font-size: 13px;
}
.layout-muestra-der {
  background: url("/public/assets/wsp-background.png");
  padding: 16px;
  width: 280px;
}
.image-preview {
  border-radius: 8px;
  object-fit: cover;
  border: none;
}
.document-preview {
  object-fit: cover;
  border: none;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-end-start-radius: 6px !important;
  border-start-start-radius: 6px !important;
}
.call-input-group {
  padding: 8px;
  border: 1px solid rgba(119, 190, 66, 0.1);
  background-color: rgba(119, 190, 66, 0.08);
  border-radius: 8px;
  width: 100%;
}
.layout-botones-call {
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d3d3d3;
}
.call {
  font-size: 14px;
  color: #00a5f4;
  height: 44px;
}
.advertencia-marketing-wrapper {
  padding: 12px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px dashed var(--primary-color);
  gap: 4px;
}
.contenido-advertencia-marketing {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ant-btn-primary:disabled {
  background-color: #f5f5f5 !important;
  color: #bdbdbd !important;
  border-color: #d9d9d9 !important;
}
.template-options-wrapper .ant-radio-wrapper {
  padding: 8px !important;
  border-radius: 8px;
}
.template-options-wrapper .ant-radio-wrapper:hover {
  background-color: var(--hover-color);
}
.muestra-layout-wrapper {
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}
.form-autocompletar {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;
}
.form-autocompletar-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.form-autocompletar-item p {
  font-weight: 600;
}
.radio-otp {
  position: relative;
}
.radio-otp .ant-radio-wrapper .ant-radio {
  position: absolute;
  top: 10px;
}
.ant-radio-wrapper {
  position: relative;
}
.radio-otp .radio-option-wrapper {
  position: relative;
  margin-left: 20px;
}
.ant-radio .ant-wave-target {
  position: relative !important;
}
.columna {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 100%;
  /* overflow: hidden; */
}
.columna > .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0px;
}
.mensaje-error {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 8px;
  border-radius: 8px;
  background-color: #ffebec;
  align-self: flex-start;
  font-weight: normal;
}
.button-type-pop-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.button-type-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  cursor: pointer;
  padding: 4px;
}
.button-type-wrapper:hover {
  background-color: #f0f0f0;
}
.button-type-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.button-type-section-title {
  font-size: 16;
  font-weight: 600;
  margin-inline: 4px;
}
.button-type-title {
  font-size: 14px;
  line-height: 18px;
}
.button-type-subtitle {
  font-size: 13px;
  color: #656565;
  line-height: 15px;
}
.type-disabled {
  color: #656565;
  cursor: not-allowed;
}
.type-disabled:hover {
  background-color: white;
}
.template-button-title {
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
}
.template-button-description {
  font-size: 13px;
  color: #656565;
}
.all-buttons-mask {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}

.render-buttons-wrapper {
  /* padding: 8px 8px 0px 8px; */
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  display: flex;
  border-radius: 6px;
}

.button-click-rate {
  /* position: absolute; */
  /* right: 4px; */
  font-weight: 500;
}
