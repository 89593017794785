.landing-page-wrapper {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* justify-content: center; */
  /* align-items: center; */
  background-color: var(--landing-background-color);
  overflow-x: hidden;
  /* font-family: ; */
}

.terms-and-privacy-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
  background-color: var(--landing-background-color);
  padding: 2.5% 5%;
  gap: 4px;
}

.terms-and-privacy-wrapper * {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.terms-and-privacy-wrapper > h4,
.terms-and-privacy-wrapper > h2 {
  margin: 1rem 0px 0.5rem 0px;
}

.terms-and-privacy-wrapper > ul,
.terms-and-privacy-wrapper > ol {
  margin: 0.5rem 1rem;
}

.brocoly-highlight {
  width: fit-content;
  /* padding-inline: 1em; */
  font-weight: 500;
  font-style: italic;
  font-family: inherit;
}
