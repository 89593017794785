div.tickets-details-form div.ant-form-item div.ant-form-item-control-input {
  min-height: 0 !important;
}

.ticket-timeline-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  margin-top: 1rem;
}

div.ticket-timeline-container div.ant-timeline-item-tail,
div.ant-timeline-item-head {
  left: 2% !important;
}

div.ticket-timeline-container div.ant-timeline-item-label {
  width: calc(10% - 12px) !important;
}

div.ticket-timeline-container div.ant-timeline-item-content {
  /* left: calc(2% - 4px) !important; */
  /* width: calc(98% - 4px) !important; */
  display: flex;
  flex: 1;
}

div.ticket-timeline-container div.ant-timeline-item-tail,
div.ant-timeline-item-head {
  left: 2% !important;
}
