.new-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 0px;
}
.new-options-item {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: var(--dark-color);
  cursor: pointer;
}

.new-options-icon-wrapper {
  display: flex;
  align-items: center;
  height: 22px;
}
