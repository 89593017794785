.main-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px;
}
.main-header {
  font-weight: 600;
  font-size: 24px;
  padding: 0px 0px 24px 0px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin-inline: auto;
}
.espacio-botones {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.main-wrapper table {
  /* margin: 1rem; */
  font-size: 13px;
}

.tabla-generica table {
  font-size: 13px;
}

.tabla-generica {
  width: 100%;
  max-width: 85%;
  max-height: 100%;
  overflow: auto;
  margin-inline: auto;
  font-size: 13px;
}

/* -ms-overflow-style: none; /* hide scrollbar for Internet Explorer and Edge */
/* scrollbar-width: none; */
/* } */
/* .tabla-generica::-webkit-scrollbar { */
/* display: none; */
/* } */

.main-wrapper tbody tr {
  height: 60px;
}

.tabla-generica tbody tr {
  height: 60px;
}

.tabla-generica th {
  background-color: white !important;
  height: 40px !important;
}
