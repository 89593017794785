.sabemos-que-top {
  position: relative;
  width: 100%;
  padding: 5rem 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* aspect-ratio: 1270 / 528; */
  /* margin-bottom: 5rem; */
}

.landing-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding-bottom: 16px;
  /* border-bottom: 1px solid #e5e7eb77; */
  width: 100%;
  max-width: 1000px;
  margin-inline: auto;
  padding: 3rem 0px;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.landing-form-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.landing-form-item .ant-form-item-label > label {
  font-family: "Space Grotesk", sans-serif !important;
  font-size: 1.125rem !important;
  line-height: 1.125rem !important;
  margin-bottom: 8px !important;
  /* font-weight: 500; */
}

.landing-form-input {
  border: none;
  border-bottom: 1px solid #989898;
  border-radius: 0px;
  max-width: 100%;
}

@media (max-width: 768px) {
  .landing-form {
    display: flex;
    flex-direction: column;
  }
}
