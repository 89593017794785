.footer-wrapper {
  display: flex;
  flex-direction: row;
  color: white;
  background-color: var(--landing-background-color);
  text-align: left;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: auto;
  margin-bottom: 3rem;
}

.footer-main-wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--landing-background-color);
  color: white;
  padding: 40px 5%;
  font-size: 1rem;
  width: 100%;
  gap: 4rem;
  align-items: center;
  position: relative;
  text-align: center;
}

.footer-main-wrapper * {
  font-family: "Space Grotesk", sans-serif;
  /* font-family: "Plus Jakarta Sans", sans-serif; */
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-column-title {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 1.125rem;
}

.footer-text {
  line-height: 24px;
  color: white;
}

.anchor-footer {
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  text-decoration: none;
  color: white;
}

.image-go-top {
  /* position: absolute;
  top: 40px;
  right: 5%; */
  cursor: pointer;
  width: 3rem;
}

/* SMART Agro */

@import url("https://fonts.googleapis.com/css2?family=Asap+Condensed:wght@400;500;600&display=swap");

.smartagro-section {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100px; */
  /* background-color: #f4f4f4; */
  /* background-image: url("https://smartagro.io/colaboraciones/bg-sa.jpg"); */
  /* background-size: cover; */
  /* background-position: center; */
  width: 100vw;
  color: white;
  /* position: absolute;
  top: 0px; */
}

.smartagro-section a {
  text-decoration: none;
}

.smartagro-section .content {
  display: flex;
  align-items: center;
}

.smartagro-section span {
  font-size: 18px;
  /* color: #1d1d1b; */
  color: white;
  font-family: "Asap Condensed", Arial, sans-serif;
}

.smartagro-section .logo {
  margin-left: 10px;
  height: 2rem;

  /* filter: invert(100%) contrast(100%) brightness(150%) grayscale(100%)
    drop-shadow(3px 3px blue); */
}

@media (max-width: 768px) {
  .footer-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
  }
}
