.tag-wrapper {
  box-sizing: border-box;
  /* border-radius: 4px; */
  display: flex;
}
.tag-custom {
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  padding: 0px 7px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  font-weight: 600;
  width: fit-content;
  max-width: 100%;
  /* height: 22px; */
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.columnas-etiquetas {
  overflow: hidden;
}
.columnas-etiquetas .ant-checkbox {
  top: 0px !important;
}
.columnas-etiquetas .ant-checkbox-checked {
  top: 0px !important;
}
