.card-wapp-wrapper {
  box-sizing: border-box;
  background-color: var(--dark-color);
  color: white;
  font-size: 14px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  margin-bottom: 30px;
}
.card-wapp-header {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px;
  width: 100%;
}
.card-wapp-description {
  display: flex;
  flex-direction: column;
  width: 400px;
}
.card-linea-wrapper {
  /* border: 1px dashed var(--primary-color); */
  /* border-radius: 8px; */
  box-sizing: border-box;
  font-size: 14px;
}
.linea-footer {
  padding: 12px;
  width: 100%;
  border-top: 1px dashed var(--primary-color);
  align-items: flex-start;
  font-size: 12px;
}
.token-permanente {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.alinear-botones {
  padding: 4px;
}
.lines-list-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-right: 12px;
  box-sizing: content-box;
}
.nombre-estado-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-weight: 600;
}
.scope-tag2 {
  font-weight: 500;
  color: #666;
}
.scope-tag3 {
  font-weight: 600;
  color: #b1b1b1;
}
.gap0 {
  gap: 0px !important;
}

.gap4 {
  gap: 4px !important;
}

.gap8 {
  gap: 8px !important;
}

.gap12 {
  gap: 12px !important;
}

.gap16 {
  gap: 16px !important;
}

.editar-perfil-form-default-text {
  font-size: 16px;
  font-weight: 600;
}

.Estadisticas {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Estadisticas-fecha {
  font-size: 20px;
  font-weight: 600;
}

.Estadisticas-row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.Estadisticas-row.cardMensajes-row {
  gap: 100px;
  padding-left: 100px;
  padding-right: 100px;
}

.EstadisticasConversaciones {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
}

.EstadisticasMensajes {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
}

.CardEstadisticas {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px #e0e0e0;
  width: fit-content;
}

.estadistica-conversaciones {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  width: 100%;
}

.estadistica-mensajes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.estadistica-total {
  font-weight: bold;
}

.estadistica-titulo {
  display: flex;
  align-items: center;
  gap: 5px;
}

.estadistica-values-container {
  display: flex;
  gap: 25px;
}

.estadistica-titulo-mensaje {
  gap: 10px;
  padding: 10px;
  font-size: 1.15em;
  font-weight: 500;
}

.estadistica-valor-mensajes {
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 2.5em;
  font-weight: 600;
}

.statInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
}

.TooltipGrafico {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  background-color: #fffd;
}

.TablaBloqueos-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.TablaBloqueos-Buscador {
  display: flex;
  gap: 10px;
  padding: 10px;
}
