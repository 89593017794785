.config-module-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.config-main-menu {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  width: 202px;
  overflow-y: auto;
  border: 1px solid rgb(229 231 235);
}
.config-module-title {
  font-size: 16px;
  font-weight: 600;
  margin: 16px 16px 8px 16px;
}
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 16px !important;
}
.ant-menu-light.ant-menu-inline .ant-menu-item {
  padding-left: 16px !important;
  padding-right: 2px !important;
  height: 32px !important;
  line-height: 32px !important;
}
.config-content-wrapper {
  height: 100%;
  display: flex;
  flex: 1;
}
.tabla-config {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; /* hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;
}
.tabla-config::-webkit-scrollbar {
  display: none;
}

.tabla-config table {
  font-size: 13px !important;
}
.tabla-config tbody tr {
  height: 67px !important;
}
.tabla-config th {
  background-color: white !important;
  height: 40px !important;
}
.config-main-wrapper {
  height: 100%;
  width: clamp(500px, 800px, 100%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 0.5rem;
  margin-left: 5rem;
  overflow: hidden;
  color: #252525;
}
.test-main-header {
  /* margin-top: 32px; */
  display: flex;
  flex-direction: column;
  /* margin-bottom: 44px; */
  margin: 32px 0px;
  width: 800px;
}
.header-title-templates {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.header-subtitle {
  font-size: 13px;
  margin-top: 2px;
  color: #4b5563;
  line-height: 20px;
  margin-left: 36px;
}

.ant-menu-light .ant-menu-item-selected span {
  color: #252525 !important;
}
.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #252525 !important;
}
.ant-menu-submenu-title {
  font-size: 14px !important;
  font-weight: 600;
  color: #252525;
}
.config-header-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.channel-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* width: clamp(200px, 230px, 50%); */
  width: fit-content;
  min-width: 170px;
  height: 140px;
  border: 1px dashed #8a8c8f99;
  border-radius: 8px;
  padding: 16px;
  font-size: 13px;
  color: #8a8c8f;
}
.integrations-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: clamp(200px, 275px, 50%);
  border: 2px dashed #8a8c8f99;
  border-radius: 8px;
  padding: 16px;
  font-size: 13px;
  color: #8a8c8f;
}
.config-catalog {
  margin-left: 36px;
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.catalog-card {
  border-radius: 8px;
  padding: 16px;
  border: 2px dashed #8a8c8f99;
  width: fit-content;
  font-weight: 500;
  color: #252525;
  font-size: 13px;
}
.horizontal-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.catalog-counter-card {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: var(--dark-color);
  color: white;
  padding: 8px;
  border-radius: 6px;
  align-items: center;
  min-width: 100px;
}
.counter-card-number {
  font-size: 26px;
  font-weight: 600;
}
.counter-card-name {
  font-size: 13px;
  font-weight: normal;
}
.team-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  padding: 16px;
  border: 2px dashed #8a8c8f99;
  width: fit-content;
}
.catalog-option-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  color: #252525;
  border: 2px solid #8a8c8f99;
  margin-bottom: 8px;
  border-radius: 8px;
  width: fit-content;
  min-width: 150px;
  max-width: 80%;
}
.catalog-option-wrapper-selected {
  border: 2px solid var(--primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  color: #252525;
  margin-bottom: 8px;
  border-radius: 8px;
  width: fit-content;
  min-width: 150px;
  max-width: 80%;
}
.catalog-content {
  max-width: 800px;
  display: flex;
  height: calc(100% - 120px);
  flex-direction: column;
}

.btn-confirmar-catalogo {
  display: none;
  margin-left: 0px;
}

.catalog-option-wrapper-selected .btn-confirmar-catalogo {
  margin-left: 32px;
  display: inline;
  transition: all 1s ease-in-out;
}

.rol-wrapper {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #adc6ff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 180px;
  overflow: hidden;
  max-height: 87px;
  box-sizing: border-box;
  background-color: #f0f5ff;
}
.rol-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.rol-content > p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #1d39c4;
}

.rol-content > p:nth-child(1) {
  font-weight: 500;
}

.rol-content > p:nth-child(2) {
  font-size: 13px;
}

.modal-articulo-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 800px;
  max-height: 100%;
}
.articulo-title {
  font-weight: 600;
  font-size: 26px;
  color: #5a5a5a;
}
.articulo-brand {
  font-size: 20px;
  color: #5a5a5a;
  font-weight: 600;
}
.articulo-subtitle {
  font-weight: 600;
  color: #252525;
}
.articulo-description {
  color: #5a5a5a;
  margin-top: 16px;
}
.articulo-price {
  font-weight: 600;
  font-size: 24px;
  margin-top: 16px;
  margin-left: auto;
}
.log-status {
  padding: 5px;
  background-color: var(--primary-color);
  border-radius: 50%;
  margin-inline: auto;
}
.popover-icon {
  padding: 8px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 4px;
  margin-inline: auto;
}
.form-top-title {
  margin-bottom: 16px !important;
}
.integration-title {
  font-size: 18px;
  font-weight: 500;
  color: #5a5a5a;
}

.ant-table-cell {
  background-color: white !important;
}

.ant-table-wrapper
  .ant-table.ant-table-small
  .ant-table-tbody
  .ant-table-wrapper:only-child
  .ant-table {
  margin-inline: 12px -8px;
}

.role-detail-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  gap: 16px;
}

.form-roles {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; /* hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;
}
.form-roles::-webkit-scrollbar {
  display: none;
}

.form-roles .ant-form-item {
  max-width: 500px;
}

.flow-defecto-wrapper {
  display: flex;
  flex-direction: column;
  margin: 16px 36px;
  gap: 20px;
  padding: 20px;
  max-width: 600px;
}

.usuarios-roles-content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0px 8px 16px 0px;
  gap: 24px;
}

.usuarios-roles-content-wrapper::-webkit-scrollbar {
  display: none;
}

.columns-visibility-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.columns-visibility-info {
  margin: 8px 0;
}

.columns-visibility-save-button {
  margin: 16px 0;
}

.DefaultBot {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 40%;
}

.default-bot-first-container {
  padding: 8px;
  gap: 16px;
}

.default-bot-first-row {
  align-items: center;
}

.default-bot-name {
  font-size: 24px;
}

.default-bot-edit {
  cursor: pointer;
}

.storage-cards-container {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
}

.storage-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  min-width: 200px;
  height: 100px;
  padding: 32px;
  font-size: 16px;
}

.storage-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.storage-card-btn {
  padding-inline: 12px;
  font-size: 12px;
  font-weight: 500;
}

.storage-pricing-container {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 32px;
}

.CardPricing {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 250px;
  padding: 32px;
}

.preview-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-modal .ant-modal-content {
  padding: 10px !important;
}
.preview-modal .ant-modal-content .ant-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TablaBloqueos-acciones {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
