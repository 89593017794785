.form-clientes {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  overflow-y: auto;
  -ms-overflow-style: none;
  /* hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;
}

.form-clientes::-webkit-scrollbar {
  display: none;
}

.form-clientes-horizontal {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
} 

.form-clientes-custom-footer {
  /* border-top: 1px solid #d4d4d4; */
  padding-top: 8px;
  position: absolute;
  width: calc(100% - 48px);
  bottom: 4px;
  background-color: white;
}