/* PISABA LA CLASE ORIGINAL DE LOS FORMULARIOS */
/* .form-custom-footer {
    padding-top: 8px;
    position: absolute;
    width: calc(100% - 48px);
    bottom: 4px;
    background-color: white;
    margin-bottom: 20px;
} */

.form-configTabla {
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    overflow-y: auto;
    -ms-overflow-style: none; /* hide scrollbar for Internet Explorer and Edge */
    scrollbar-width: none;
    padding: 24px;
}

.form-columnsItem {
    display: flex;
    justify-content: space-around;
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 20px;
}

.form-columnsItem-noFixed {
    font-size: 14px;
    margin-right: 10px;
}

.form-columnsItem-fixed {
    font-size: 14px;
    margin-right: 10px;
    color: #54c200;
    font-weight: 600
}

#rgFixed .ant-radio-button-wrapper:hover{
    color: #000000E0 !important;
    border-color: #77BE42;
}


#rgFixed .ant-radio-button-wrapper-checked{
    color: #000000E0 !important;
}

.form-label-columns {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 8px;
}

.form-chk-col-list {
    display: flex;
    align-items: center;
    height: 37px;
}

.form-col-list-contenedor {
    overflow-y: scroll;
    height: 200px;
}

.form-col-list-alert {
    font-size: 14px;
    color: #ff4d4f;
    margin-top: -20px;
}

.form-label-fijar {
    font-size: 14px;
    font-weight: 600;
    padding-left: 24px;
    padding-bottom: 8px;
}

.form-col-fijar-contenedor {
    display: flex;
    justify-content: center;
    overflow-y: scroll;
}

.form-col-fijar-options {
    display: flex;
    flex-direction: row;
}

/*Filtro tabla*/

#valorNumerico.ant-input-number-input {
    text-align: left !important;
}

.filtroCreado {
    display: flex;
    justify-content: space-around;
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 8px;
}

.filtro-btn-or {
    margin: 12px 0;
    color: #506E91;
    font-size: 12px;
    background-color: #EAF0F6;
    padding: 5px 12px;
}

.filtro-btn-and {
    margin-top: 12px;
    color: #506E91;
    font-size: 12px;
    background-color: #EAF0F6;
    padding: 5px 12px;
}

.filtro-label-y {
    padding: 8px 0;
    color: #99ACC2;
    line-height: 1rem;
}

.filtro-label-o {
    padding: 8px 0;
    line-height: 1rem;
    font-weight: 600;
}

.filtro-iconos-acciones {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.filtro-filtro-contenedor {
    display: flex;
    justify-content: space-between;
    padding: 12px 8px 12px 16px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    gap: 8px;
}

.filtro-list {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 8px;
    background-color: white;
}

.filtro-form-contenedor {
    overflow-y: scroll;
    display: flex;
    justify-content: center;
}

.filtro-form-btns-accion {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.filtro-btn-agregarFiltro{
    display: flex;
    justify-content: center;
    align-items: center;
}

.filtro-form-animation {
    animation-name: despliegue-vertical;
    animation-duration: 0.5s;
}

.filtro-loading {
    display: grid;
    height: 300px;
    width: 100%;
    place-items: center;
}

@keyframes despliegue-vertical{
    0%{
        transform: translateY(-8%);
    }

    100%{
        transform: translateY(0%);
    }
}

.filtro-form-btns-popup {
    animation-name:popup-form-filtro;
    animation-duration: 0.3s;
}

@keyframes popup-form-filtro{
    0%{
        transform: scale(80%);
        /* transform: translateX(-80%); */
    }

    100%{
        transform: scale(100%);
        /* transform: translateX(0%); */
    }
}