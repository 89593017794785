.flexContainer {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  /* align-items: flex-start;
  justify-content: flex-start; */
}

.mainView {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  height: 100%;
  width: calc(100% - 70px);
}

.pageHeader {
  background-color: #ffffff;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px 24px;
}

.iconHeader {
  font-size: 1.4em;
}

.pageContent {
  flex-direction: row;
  height: 100%;
  flex-wrap: nowrap;
  box-sizing: border-box;
}

.pageContent-animated {
  flex-direction: row;
  height: 100%;
  flex-wrap: nowrap;
  box-sizing: border-box;
  animation-name: popup-page-content-animated;
  animation-duration: 0.3s;
}

@keyframes popup-page-content-animated {
  0% {
    transform: scale(90%);
  }

  100% {
    transform: scale(100%);
  }
}

.col-mainchildren {
  width: 100%;
  height: 100%;
}

.sidebar {
  height: 100%;
  background-color: var(--dark-color);
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1001;
  overflow-y: auto;
  -ms-overflow-style: none; /* hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;
}

.sidebar-animated {
  height: 100%;
  background-color: var(--dark-color);
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1001;
  overflow-y: auto;
  -ms-overflow-style: none; /* hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;
  /* animation-name: despliegue-sidebar-animated; // El cambio en el modo de importación hace que se repita la animación
  animation-duration: 0.3s; */
}

@keyframes despliegue-sidebar-animated {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
}
.sidebar-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 1.1em; */
}

.logo-container {
  height: 4em;
  margin-top: 0.28em;
}

.icon-menu {
  color: white;
  font-size: 24px;
}

.icon-menu-selected {
  color: var(--dark-color);
  font-size: 24px;
}

.menu-tools {
  margin-top: 1em;
  margin-bottom: 1em;
}

.menu-modulos {
  margin-top: 1em;
  margin-bottom: 1em;
}

.item-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 3px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.workspace-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 3px;
  margin-bottom: 1em;
  margin-top: 0.8em;
  background-color: #ffffff;
}

.workspace-menu-image {
  width: 38px;
  border-radius: 3px;
  cursor: pointer;
}

.workspace-menu:hover {
  cursor: pointer;
}

.user-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  /* border-radius: 50px; */
  margin-bottom: 1.3em;
  margin-top: 0.3em;
  background-color: #ffffff;
}

.user-menu:hover {
  cursor: pointer;
}

.user-menu-image {
  /* width: 38px; */
  border-radius: 50%;
}

.user-menu-image-workspace {
  border-radius: 3px;
}

.item-menu:hover {
  background-color: rgba(220, 224, 86, 0.6);
  /* background-color: #f4f4f4; */
  cursor: pointer;
}

.item-menu:active {
  background-color: #2b4e61;
}

.item-menu-selected {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 3px;
  background-color: rgb(220, 224, 86);
  margin-top: 4px;
  margin-bottom: 4px;
}

.item-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 3px;
  background-color: white;
}

.item-header-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 3px;
  background-color: var(--dark-color);
}

.item-header:hover {
  background-color: #f6f6f6;
  cursor: pointer;
}

.header-icon {
  color: var(--dark-color);
  font-size: 20px;
}

.header-icon-selected {
  color: white;
  font-size: 20px;
}

.header-menu-wrapper {
  display: flex;
  margin-left: auto;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
  -o-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.header-menu-wrapper-left {
  display: flex;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
  -o-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.ant-popover-title {
  min-width: 0px !important;
}

@media (max-width: 700px) {
  .sidebar {
    display: none;
  }
  .mainView {
    display: none;
  }

  .mensaje-mediaquery {
    margin: auto;
    font-weight: 600;
    text-align: center;
  }
}

@media (min-width: 700px) {
  .header-menu-wrapper-left {
    display: none;
  }
  .mensaje-mediaquery {
    display: none;
  }
}

.search-contenedor-principal {
  height: 100%;
  width: 100%;
  color: #444;
}
.ant-input:hover {
  border: 1px solid var(--primary-color) !important;
}

.ant-input:focus,
.ant-input-focused {
  border: 1px solid var(--primary-color) !important;
  box-shadow: 0 0 0 2px rgba(125, 184, 40, 0.2) !important;
}

.busqueda-recientes-contenedor {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
}

.busqueda-reciente-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 8px 0px;
}

.busqueda-card-imagen {
  width: 15%;
}

.busqueda-reciente-card:hover {
  background-color: #d9edf7;
}

.busqueda-card-descripcion {
  display: flex;
  flex-direction: column;
}

.ant-drawer .ant-drawer-header {
  display: none !important;
}
.asignar-contactos-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.asignar-header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ant-transfer .ant-transfer-list-with-pagination {
  width: 100% !important;
}

.user-data-item {
  cursor: pointer;
  width: fit-content;
}

.user-data-item:hover > p {
  color: var(--primary-color);
}

.brocoly-water-mark {
  position: absolute;
  right: 4px;
  bottom: 4px;
}

.pie-graph-wrapper {
  height: 60px;
  width: 80px;
  display: flex;
  justify-content: center;
}

.custom-tooltip-label {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}
.custom-tooltip-wrapper {
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
}

.custom-variable-reference {
  color: var(--primary-color);
}

.columna-estadisticas {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 8px;
  border-right: 1px solid #d8d8d8;
}

.columna-estadisticas-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 8px;
  border-right: 1px solid #d8d8d8;
  width: calc(33.33% - 1px);
}

.campaign-graph-wrapper {
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 100%;
  /* border: 1px solid #d8d8d8;
  border-radius: 8px; */
}

.stats-header {
  /* border: 1px solid #d8d8d8; */
  border-radius: 8px;
  width: calc(85% - 16px);
  margin-bottom: 16px;
  margin-inline: auto;
  padding: 16px;
  gap: 0px;
}

.filaItemsContacto {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
}

.campaignStat .ant-statistic-title {
  font-size: 20px;
}

.collapse-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.Collapse .collapse-title {
  font-size: 18px;
  font-weight: 500;
}

.Collapse .collapse-item {
  display: flex;
  align-items: center;
}

.collapse-content {
  margin-top: 12px;
}

.ItemContacto {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin: 8px 0;
}

.map-container {
  height: 400px;
}

.warning-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px;
}

.warning-modal-title {
  margin: 16px;
  font-size: 1.5rem;
  font-weight: 500;
}

.warning-modal-text {
  font-size: 1.2rem;
  color: #555;
}

.desactivar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 4px;
  margin-top: auto;
  margin-bottom: 16px;
  padding: 8px;
  border: 1px dashed #ff6966;
  border-radius: 6px;
}

.ShowContact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
}

.show-contact-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.show-contact-close-btn {
  display: flex;
}

.show-contact-name {
  margin-top: 16px;
  font-size: 1.75rem;
  font-weight: 600;
}

.show-contact-job {
  margin: 8px 0;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: #8a8c8f;
}

.show-contact-table {
  width: 100%;
  border-spacing: 0 16px;
}

.show-contact-label {
  vertical-align: top;
  text-align: left;
  font-weight: 600;
}

.show-contact-value {
  vertical-align: top;
  text-align: left;
  color: #555;
}

.show-contact-clients-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.show-assistant-info-label {
  align-self: flex-start;
  color: #666;
}

.CampaignTab {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.timeline-collapse .ant-collapse-header {
  padding-top: 0 !important;
  padding-bottom: 8px !important;
  /* padding: 8px !important; */
}

.timeline-collapse .ant-collapse-content-box {
  padding: 8px !important;
}

.logs-timeline-dot-wrapper {
  /* padding-left: 8px; */
  /* position: absolute; */
  /* top: 0px;
  left: 0px; */
}

.assistant-custom-modal .ant-modal-content {
  padding: 12px 20px;
}

.show-assistant-info-subcontainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  margin: 16px 0;
  padding: 12px;
  background-color: #fff;
  border-radius: 8px;
}

.show-assistant-name {
  font-size: 1.75rem;
  font-weight: 600;
}

.show-assistant-job {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: #8a8c8f;
}
.BuscadorTabla {
  display: flex;
  gap: 8px;
  padding: 8px;
}

.show-assistant-container {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4) inset;
}

.sentiment-card-ticket {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.sentiment-card-ticket-label {
  color: #71717a;
  font-weight: 500;
}
