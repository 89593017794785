.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 5%;
  background-color: var(--landing-background-color);
  color: white;
  width: 100%;
}

/* font-family: "Plus Jakarta Sans", sans-serif; */

.header-wrapper * {
  font-family: "Space Grotesk", sans-serif;
}

.boton-landing-header {
  padding: 6px 11px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid white;
  box-shadow: none;
}

.boton-landing-header-secondary {
  padding: 6px 11px;
  color: white;
  border-radius: 20px;
  background-color: inherit;
  border: 1px solid var(--landing-background-color);
  box-shadow: none;
}

.landing-boton-oscuro {
  background-color: var(--landing-background-color);
  color: white;
}

.landing-boton-highlight {
  background-color: var(--landing-highlight-color);
  color: var(--landing-background-color);
}

.landing-boton-highlight:hover {
  background-color: white;
  color: var(--landing-background-color);
}

.landing-header-buttons-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.landing-mobile-menu {
  display: none;
}

@media (max-width: 768px) {
  .landing-header-buttons-row {
    display: none;
  }

  .landing-mobile-menu {
    display: flex;
    margin-left: auto;
  }

  .popover-menu-mobile {
    background-color: var(--landing-background-color);
    padding: 0;
  }
}
