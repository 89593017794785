.presupuestos-tabs-wrapper {
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-inline: auto;
}

.presupuesto-layout-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  
}