.contact-filter-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  gap: 4px;
}
