.ExtrasColumn {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 100vh;
  padding: 16px;
  border-radius: 8px;
  overflow-y: auto;
  gap: 24px;
}

/* BUSCADOR */
.extras-buscador {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  /* margin: 0 8px 16px 8px; */
}
.extra-buscador-checkbox {
  display: flex;
  flex-direction: column;
}

/* ETIQUETAS */
/* .ExtrasTags {
  margin-top: 16px;
  margin-bottom: 32px;
} */
.extras-sin-etiquetas {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #182229aa;
}

/* CALENDARIO */
/* .ExtrasCalendar {
  margin-bottom: 16px; 
} */

.ExtrasCalendar tr {
  height: 30px !important;
}
.cell-calendar {
  display: inline-block;
  min-width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s;
}
.cell-calendar:hover {
  background-color: #77be4255;
}
.cell-calendar-otherMonth {
  color: rgba(0, 0, 0, 0.88);
  color: #777;
  opacity: 0.5;
}
.cell-calendar-today {
  color: #e59e23;
  font-weight: 800;
}
.cell-calendar-haveTickets {
  border: solid 1px #77be42;
  color: #77be42;
}
.cell-calendar-selected {
  background-color: #77be42;
  color: #fff;
}

.HeaderCalendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.header-calendar-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
}
.header-calendar-selectors {
  display: flex;
  gap: 8px;
}

/* TABS */
.extras-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}
.extras-section-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
  overflow-y: auto;
  max-height: 45vh;
}

.extras-contador {
  color: #aaae;
}

.extras-no-hay {
  margin-block: 16px;
  font-size: 1.25em;
  text-align: center;
  color: #182229aa;
}

/* TICKETS */
.Ticket {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border: solid 1px #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
}
.ticket-dateTime {
  font-size: 14px;
}
.ticket-tags-container {
  display: flex;
  gap: 8px;
}
.ticket-actions-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ticket-actions-container .ant-badge .ant-badge-count-sm {
  background-color: #e59e23 !important;
  color: #fff !important;
}
.ticket-date-container {
  display: flex;
  gap: 8px;
}

/* NOTES */
.Note {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  background-color: #fffbeb;
  border: 1px solid #fbd44c;
  border-radius: 5px;
  cursor: pointer;
}
.note-dateTime {
  align-self: flex-end;
  font-size: 14px;
}

/* MEDIA */
.Media {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border: solid 1px;
  border-radius: 5px;
  cursor: pointer;
}
