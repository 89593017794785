.main-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px;
}
.main-header {
  font-weight: 600;
  font-size: 24px;
  padding: 0px 0px 24px 0px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin-inline: auto;
}
.espacio-botones-tablaClientes {
  /* display: flex; */
  display: none;
  flex-direction: row;
  gap: 8px;
}
.icono-acciones {
  font-size: 18px;
  cursor: pointer;
}

/* custom Scroll */
.tabla-tablaClientes
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-container
  > .ant-table-content {
  scrollbar-color: #b4b4b4 transparent; /* Color de scroll - Color fondo de scroll */
  scrollbar-width: thin; /* Ancho de scroll */
}

/*Modal*/
/* .modal-editCol-tablaClientes  .ant-modal-header {
  padding-bottom: 8px;
} */

/*Tabla draggable*/
.draggable-table-tablaClientes
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-container
  > .ant-table-content
  > table
  > .ant-table-thead {
  display: none;
}

.draggable-table-tablaClientes
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-container
  > .ant-table-content
  > table {
  font-size: 13px;
}

.tabs-TablaClientes {
  width: 85%;
  margin-inline: auto;
}

.btn-agregar-tabla {
  display: flex !important;
  align-items: center;
  background-color: var(--primary-color) !important;
  border-radius: 6px !important;
  width: fit-content;
  gap: 8px;
}

/* Esto consultarlo, porque los estilos generales en style.css estan afectando de manera no deseada a estos tabs, por lo tanto el codigo css siguiente es para contrarrestar dichos efectos*/
.tabs-TablaClientes
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > div {
  padding: 8px 16px !important;
  border-bottom: none !important;
}

.tabs-TablaClientes
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > div:hover {
  opacity: 100%;
}

.tabs-TablaClientes
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab-with-remove
  > .ant-tabs-tab-remove {
  margin-right: -8px !important;
}
