@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Space+Grotesk:wght@300..700&display=swap');

.botones-wrapper-content {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.btn-guardar {
  width: calc(50% - 4px);
  min-width: fit-content;
  background-color: var(--primary-color) !important;
  color: white;
  border-radius: 6px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-borrar {
  width: calc(50% - 4px);
  background-color: #ff6961 !important;
  color: white;
  border-radius: 6px !important;
}
.btn-guardar .ant-btn:disabled {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: not-allowed;
  border-color: #d9d9d9;
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.25);
  box-shadow: none;
}
.btn-guardar:hover {
  color: white !important;
}
.btn-cancelar {
  width: calc(50% - 4px);
  min-width: fit-content;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 6px !important;
}
.btn-agregar {
  display: flex !important;
  align-items: center;
  background-color: var(--primary-color) !important;
  border-radius: 6px !important;
  width: fit-content;
  gap: 8px;
}

.btn-agregar .ant-btn-primary:disabled {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: not-allowed;
  border-color: #d9d9d9;
  background-color: #f5f5f5 !important;
  color: #bdbdbd;
  box-shadow: none;
}

.boton-dashed {
  height: 38px !important;
  background-color: white !important;
  color: var(--primary-color);
  border: 1px dashed var(--primary-color);
}
.boton-dashed-32 {
  background-color: white !important;
  color: var(--primary-color);
  border: 1px dashed var(--primary-color);
}

.btn-oscuro {
  background-color: var(--dark-color) !important;
  border-radius: 6px !important;
  color: white;
}

.boton-secundario {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.btn-secundario {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: var(--dark-color) !important;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color) !important;
}
.ant-tabs .ant-tabs-tab:hover {
  color: var(--primary-color) !important;
  opacity: 0.6;
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: var(--primary-color) !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-lij74fp).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px rgba(5, 255, 145, 0.1) !important;
}

.ant-drawer .ant-drawer-header {
  padding: 0px !important;
  border-bottom: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid var(--primary-color) !important;
}

.ant-tabs .ant-tabs-tab {
  padding: 8px 0 !important;
}
.titulo-content {
  font-size: 20px;
  color: var(--primary-color);
}
.ant-form-item .ant-form-item-label > label {
  display: flex !important;
}
.card-apikey {
  border: 1px dashed var(--primary-color);
  margin: 6px 0px 12px;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
}
.apikey-content {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  word-wrap: break-word;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
}
.apikey-name {
  width: calc(100% - 84px);
  box-sizing: border-box;
  word-wrap: break-word;
}
.apikey-footer {
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1px;
  font-size: 12px;
}
p {
  margin: 0px;
  max-width: 100%;
  word-wrap: break-word;
}
.instancia-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
}
.btn-nuevo {
  margin-left: auto;
  background-color: var(--primary-color);
  color: white;
}
.scope-tag {
  color: var(--dark-color);
  font-weight: 600;
  border-radius: 4px;
}
.scope {
  background-color: #beeb7d;
  color: var(--dark-color);
  border: 1px solid #b9babe;
  padding: 2px 8px;
  font-weight: normal;
  width: fit-content;
}
.indice-key {
  padding: 2px 8px;
  background-color: #beeb7d;
  color: var(--dark-color);
  border: 1px solid #98b174;
  border-radius: 4px;
  align-self: flex-start;
}
.apikey-footer-division {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(177, 177, 177);
  font-size: 12px;
  font-weight: 500;
}
.creacion {
  width: 30%;
}
.uso {
  width: 45%;
}
.estado {
  width: 25%;
}
.btn-revocar {
  background-color: #d33;
}
button:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
}
.celda-tags {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  width: auto;
  height: 40px;
  overflow-x: auto;
  scrollbar-color: #e0e0e0 transparent;
  scrollbar-width: thin;
  scrollbar-gutter: auto;
}

.caja-botones-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 20px;
}
.checks-etiquetas {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.keys-list-wrapper {
  height: calc(100% - 160px);
  width: calc(100% + 5px);
  overflow-y: scroll;
  padding-right: 50px;
  box-sizing: content-box;
}
.linea-token-usuario {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.input-blureado {
  color: transparent !important;
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.5) !important;
}
.input-desabilitado {
  color: black;
}
.ant-tabs {
  height: 100%;
}
.ant-tabs .ant-tabs-content {
  height: 100%;
}
.ant-tabs .ant-tabs-tabpane {
  height: 100%;
}
.conf-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0px 24px;
}
.conf-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* max-width: 450px; */
  /* margin-left: 36px; */
  padding-bottom: 24px;
  overflow-y: auto;
  -ms-overflow-style: none; /* hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;
}
.conf-content::-webkit-scrollbar {
  display: none;
}

.conf-vacio {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
.ant-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.conf-image {
  display: flex;
  position: absolute;
  bottom: -0px;
}
.card-config {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: var(--dark-color);
  width: clamp(200px, 100%, 400px);
  color: white;
}
.card-config-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 8px 8px 8px;
  box-sizing: border-box;
}
.card-config-description {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  gap: 4px;
}
.card-config-description span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.card-config-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}
.config-item-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.instance-id-createdat {
  width: 50%;
}
.instance-state-wrapper {
  margin-left: auto;
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.instance-id {
  color: #aaa;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}
.config-resumen {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  color: #aaa;
}
.config-resumen span {
  display: flex;
  align-items: center;
  gap: 4px;
}
.tabs-config {
  height: 100%;
}
.ant-input-affix-wrapper > input.ant-input:hover {
  border: none !important;
}

.ant-input-affix-wrapper > input.ant-input:focus {
  border: none !important;
}
.conf-wrapper .tabs-config {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.popover-overlay {
  height: 100vh;
  height: 100svh;
  width: 100vw;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
}
.instance-wrapper {
  max-width: 736px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.api-key {
  width: 250px;
}

.blurred {
  cursor: pointer;
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

.half-50 {
  width: 50%;
}

.form-contact-footer {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
}

.form-edit-additional-fields {
  height: calc(100% - 100px) !important;
}

.horizontal {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.ant-select-multiple .ant-select-selection-overflow {
  flex: none !important;
}

.custom-modal-chat .ant-modal-content {
  padding: 12px !important;
}