/* @media (min-width: 801px) and (max-width: 1281px) {
  .chats-list-column {
    width: 280px;
  }

  .selected-chat-column {
    width: calc(100% - 280px);
  }

  .chat-extras-column {
    width: 250px;
  }

  .ExtrasColumn {
    width: 250px !important;
  }
} */

/* @media (max-width: 800px) {
  .chats-list-column {
    width: 250px;
  }

  .selected-chat-column {
    width: calc(100% - 250px);
  }

  .chat-extras-column {
    width: 220px;
  }

  .ExtrasColumn {
    width: 220px !important;
  }
} */

/* @media (max-width: 1250px) {
  #opciones-chats {
    display: none;
  }
} */

@media (max-width: 1000px) {
  #header-numero-contacto {
    display: none;
  }
}
