.btn-config-container {
    display: flex;
    align-items: center; 
    gap: 8px;
    justify-content: space-between;
    min-width: 86px;
}

.baja-clients {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
}