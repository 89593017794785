@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;800&display=swap");

.ant-drawer-content-wrapper {
  margin-left: 70px !important;
  max-width: calc(100vw - 70px);
  transition: none;
}
.ant-form-item {
  margin-bottom: 20px;
}

code {
  font-family: monospace;
}

* {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Inter", "Segoe UI", sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  height: 5px;
  /* display: none; */
}
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 8px;
  -webkit-border-radius: 8px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
::-webkit-scrollbar-track-horizontal {
  background: transparent;
  height: 5px;
}
::-webkit-scrollbar-thumb-horizontal {
  background: #aaa;
  border-radius: 8px;
}

.ant-btn-primary {
  height: auto !important;
}

.main-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: #0000001f;
}

/* .entry-view-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
} */

.entry-loading-wrapper {
  margin-bottom: 20%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-form-item-label {
  padding: 0 0 2px !important;
}
