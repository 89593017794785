.main-content {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  border-radius: 8px;
}
.chats-list-column {
  display: flex;
  height: 100%;
  width: 30%;
  overflow-y: hidden;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}
.selected-chat-column {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  width: 70%;
  box-sizing: border-box;
  position: relative;
  height: 100%;
}
.selected-chat-column-ticket {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: 100%;
}
.background-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100% - 56px);
  box-sizing: border-box;
  width: 100%;
  background-color: #f4f4f4;
}
/* .background-container::before {
  content: "";
  background-image: url("/public/assets/wsp-background.png");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.4;
} */
.chat-extras-column {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 360px;
  overflow-y: auto;
  height: 100%;
}
.chat-contact-wrapper {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  border: 1px solid #fafafa;
  background-color: #fafafa;
  width: 100%;
  position: relative;
}

.fade-in {
  opacity: 0;
  animation: smooth-appear 1s ease forwards;
}

.linea-puntos {
  border-top: 2px dashed #e8e8e8;
  width: 100%;
  position: absolute;
  padding: 0px 8px;
}

.chat-contact-wrapper-title {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid #dfdfdf;
  background-color: white;
  height: 56px;
  overflow: hidden;
}
.chat-contact-wrapper:hover {
  background-color: #f4f4f4;
  border-color: #fafafa;
}
.chat-contact-name {
  font-size: 14px;
  font-weight: 600;
  color: #252525;
}
.chat-contact-display-message {
  color: #666;
  font-size: 13px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 80%;
}
.chat-interaction-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  padding: 16px 16px 16px 16px;
  overflow-y: auto;
  position: relative;
  width: 100%;
}
.chat-messages-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.chat-input-wrapper {
  display: flex;
  position: relative;
  border-radius: 8px;
  padding: 4px 16px 16px 16px;
  width: 100%;
  overflow: hidden;
  height: 190px !important;
}

@keyframes smooth-appear {
  to {
    opacity: 1;
  }
}

.custom-button-send {
  padding: 8px;
  background-color: green;
  color: white;
  font-size: 20px;
}
.ant-menu-light.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-jtai9z).ant-menu-light
  > .ant-menu.ant-menu-root.ant-menu-inline,
:where(
    .css-dev-only-do-not-override-jtai9z
  ).ant-menu-light.ant-menu-root.ant-menu-vertical,
:where(.css-dev-only-do-not-override-jtai9z).ant-menu-light
  > .ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}

/* .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 32px !important;
  line-height: 32px !important;
} */
.ant-checkbox + span {
  width: 100% !important;
}
.chat-input-wrapper .ant-input:hover,
.chat-input-wrapper .ant-input:active,
.chat-input-wrapper .ant-input:focus {
  border: none !important;
  box-shadow: none !important;
}
.chat-inner-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid white;
  overflow: hidden;
}
.input-note {
  background-color: #fffbeb;
  border-color: #fbd44c;
}
.chat-inner-input-wrapper .ant-result {
  padding: 8px !important;
}
.chat-bubble-place {
  display: flex;
  flex-direction: column;
  width: clamp(100px, 100%, 60%);
  box-sizing: border-box;
}
.chat-bubble-place-right {
  display: flex;
  flex-direction: column;
  width: clamp(100px, 100%, 60%);
  box-sizing: border-box;
  margin-left: auto;
}
.chat-bubble {
  min-width: 100px;
  min-height: 25px;
  width: fit-content;
  max-width: 60%;
  overflow-x: auto;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 4px;
}
.chat-bubble-right {
  margin-left: auto;
}
.chat-bubble-document {
  width: 310px !important;
}
.chat-timestamp-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 4px;
}
.chat-bubble-note {
  background-color: #fffbeb !important;
  border: 1px solid #fbd44c !important;
  border-radius: 4px;
  width: fit-content;
  max-width: 60%;
  min-width: 150px;
  margin-inline: auto;
}

.chat-bubble-system {
  background-color: #efefef !important;
  border: 1px solid #d8d8d8 !important;
  color: #686868;
  border-radius: 4px;
  width: fit-content;
  max-width: 60%;
  min-width: 150px;
  margin-inline: auto;
  font-size: 12px;
}

.chat-input-wrapper .ant-result .ant-result-extra {
  margin: 16px 0 0 0 !important;
}
.chat-input-wrapper .ant-result .ant-result-icon {
  margin-bottom: 8px !important;
}
.chat-input-wrapper .ant-btn > span {
  display: flex !important;
}

.chats-list-column .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100% !important;
  display: flex !important;
  gap: 16px;
  padding: 0px 12px;
  /* justify-content: space-between !important; */
}
.chats-list-column .ant-tabs-nav-list .ant-tabs-tab {
  /* width: 16.6666% !important; */
  justify-content: center;
  font-size: 13px !important;
}
.chats-list-column .ant-tabs-top > .ant-tabs-nav {
  margin: 0px 0px 8px 0px !important;
}

.chats-list-column .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0px !important;
}
.chat-list-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding-inline: 8px;
  gap: 4px;
}
.time-to-expired-wrapper {
  font-size: "13px";
  gap: 4px;
  padding: 4px 8px;
  margin-left: -1px;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
  background-color: white;
}
.ant-badge .ant-badge-count-sm {
  font-size: 10px !important;
  color: #252525 !important;
}
.chat-date-wrapper {
  font-size: 13px;
  padding: 2px 6px;
  /* background-color: #182229aa; */
  background-color: #aeaeae;
  /* color: #cecece; */
  color: #f4f4f4;
  font-weight: 500;
  border-radius: 4px;
  margin-inline: auto;
  position: relative;
}
.message-date-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.chat-extras-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid #dfdfdf;
  padding: 8px;
  min-height: 56px;
}
.selected-contact-detail {
  display: flex;
  flex-direction: column;
  background-color: white;
  flex: 1;
  /* height: calc(100% - 54px); */
  overflow-y: auto;
  gap: 8px;
  padding-top: 8px;
}
.contact-detail {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px;
}
.detail-title {
  color: #182229aa;
  font-size: 13px;
}
.detail-content {
  color: #252525;
}
.notes-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.note-placeholder {
  border-radius: 6px;
  max-width: 100%;
  width: fit-content;
  /* word-break: normal; */
  background-color: #fffbeb;
  border: 1px solid #fbd44c;
  padding: 8px;
  color: #252525;
  cursor: pointer;
}
.files-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px;
  gap: 8px;
  box-sizing: border-box;
}
.file-placeholder {
  width: calc(33% - 6px);
  background-color: #18222934;
  border-radius: 4px;
  aspect-ratio: 1/1;
}
.contact-section {
  background-color: white;
  margin-inline: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
}
.contact-detail iframe {
  border-radius: 8px;
  border: none;
  width: 100%;
  aspect-ratio: 1/1;
}
.message-document-name {
  width: calc(100% - 88px);
  word-wrap: break-word;
  overflow: hidden;
}

.tabs-content-spin-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 20%;
}

.expiration-countdown-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-inline: auto;
  font-size: 13px;
  gap: 4px;
}

.notification-count-position {
  /* position: absolute;
  top: 8px;
  right: 8px; */
  color: white;
  background-color: var(--whatsapp-color);
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  margin: 0px;
  display: grid;
  place-items: center;
}

.notification-count {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  padding: 2px;
}

.not-selected-wrapper {
  width: 80%;
  max-width: 600px;
  padding: 24px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inactive-user-alert {
  position: absolute;
  right: 16px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}

.chat-alert-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  padding: 6px 8px;
  color: #444;
  width: fit-content;
}

.inactive-glow {
  border-radius: 50%;
  color: #ff4141;
  text-shadow: 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00,
    0 0 60px #f00, 0 0 70px #f00, 0 0 80px #f00;
}

.blink {
  animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.ticket-resumen {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 6px;
  border-radius: 4px;
  background-color: #f8f8f8;
  border: 1px solid #d8d8d8;
  gap: 6px;
  flex-wrap: wrap;
  cursor: pointer;
}

.rotate-90 {
  animation: rotate90deg 400ms ease forwards;
}

@keyframes rotate90deg {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.rotate-0 {
  animation: rotate0deg 400ms ease forwards;
}

@keyframes rotate0deg {
  from {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.attachment-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 4px;
  border-radius: 6px;
  background-color: rgb(220, 248, 198);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.header-caption {
  margin: 8px 0px 4px 0px;
}

/* Collapse personalizado */

.chats-list-column .ant-collapse {
  height: 100%;
}

.secondary-chat-information {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  /* font-weight: 600; */
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
}

.infinite-scroll-component {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-inline: 4px;
  height: auto;
}

.custom-avatar-userpic {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  position: relative;
  font-family: Arial, sans-serif;
}

.custom-avatar-userpic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.custom-avatar-userpic .fallback-avatar-userpic {
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  background-color: #888;
  border-radius: 50%;
}

.collapse-clients-list .ant-collapse-header {
  padding: 0 !important;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5) !important;
}

.collapse-clients-list .ant-collapse-content-box {
  padding: 0 !important;
}

.collapse-clients-list {
  width: fit-content !important;
}

.chat-selected-account {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}

.chat-selected-account svg {
  margin-right: 4px;
  vertical-align: middle;
}

/* boton menu en mensajes */
.hover-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
  cursor: pointer;
  font-size: 16px;
  color: black;
  z-index: 2;
}

.chat-bubble:hover .hover-icon {
  display: block;
}


/* .chat-bubble:hover::before {
  content: "";
  position: absolute;
  top: 4px;
  right: 3px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: inherit;
  filter: blur(5px);
  z-index: 1;
  pointer-events: none;
} */

.has-menu:hover::before {
  content: "";
  position: absolute;
  top: 4px;
  right: 3px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: inherit;
  filter: blur(5px);
  z-index: 1;
  pointer-events: none;
}

.popover-container {
  width: 555px;
  height: 146px;
  overflow: hidden;
  position: relative;
}

.slide-container {
  display: flex;
  width: 200%;
  transition: transform 0.2s ease-in-out;
}

.category-list,
.replies-list {
  width: 50%;
  flex-shrink: 0;
  position: relative;
  transition: transform 0.2s ease-in-out;
}

.show-replies {
  transform: translateX(-50%);
}

.clickable {
  cursor: pointer;
}

.clickable:focus {
  outline: none;
  background-color: #DCF8C6;
  border-radius: 4px;
}

.back-icon {
  cursor: pointer;
  margin-right: 8px;
}

.search-results{
  width: 100%;
}

.search-results .ant-list-empty-text {
  padding: 0px;
}