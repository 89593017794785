.edgebutton {
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 12px;
  padding: 4px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}
.edgebutton-foreignobject div {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}
.react-flow__edge-path-selector:hover {
  cursor: pointer;
}
.react-flow__edge-path-selector:hover + .react-flow__edge-path,
.react-flow__edge-path:hover {
  stroke: #555;
  cursor: pointer;
}
.react-flow__edge-path-selector {
  fill: none;
  stroke: transparent;
  stroke-width: 25px;
}

.react-flow__edge.animated path {
  stroke-width: 2px !important;
}
