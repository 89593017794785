.input-formatted-text-area {
  width: 100%;
  /* height: 100%; */
  font-family: "Inter", "Segoe UI", sans-serif;
}


.text-area-preview {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: "Inter", "Segoe UI", sans-serif;
  /* line-height: 25px; */
  width: 100%;
  padding: 4px 11px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  font-size: 14px;
  min-height: 54px;
}
