:root {
  --gray-text: #8a8c8f;
  --gray-transparent: #8a8c8f55;
}

/* CARD DE INTEGRACIÓN */

.integrations-cards-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  gap: 8px;
}

@media (max-width: 1300px) {
  .integrations-cards-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.IntegrationCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding: 16px;
  border: 1px dashed var(--gray-transparent);
  border-radius: 8px;
}

.integration-card-main {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.integration-card-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.integration-card-icon-container {
  display: grid;
  place-items: center;
  /* width: 38px; */
  /* height: 38px; */
  width: 48px;
  height: 48px;
  border: 1px solid var(--gray-transparent);
  border-radius: 4px;
}

.integration-card-icon {
  padding: 4px;
  border: 1px solid var(--gray-transparent);
  border-radius: 4px;
}

.integration-card-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.integration-card-title {
  font-weight: 600;
}

.integration-card-description {
  color: var(--gray-text);
}

.integration-card-settings-btn {
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
}

/* DRAWER DE INTEGRACIÓN */

.integration-drawer-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
}

.integration-drawer-title-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.integration-drawer-title-container span {
  color: var(--gray-text);
}

.integration-drawer-content {
  margin-bottom: 8px;
}

.integration-drawer-vertical-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.integration-drawer-vertical-input .ant-form-item {
  margin-bottom: 4px !important;
}

.integration-drawer-label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.integration-drawer-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.integration-drawer-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.integration-drawer-form {
  margin: 16px 0;
}

.integration-drawer-form .ant-form-item {
  margin-bottom: 8px !important;
}
