.budget-config-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.example-post-wrapper {
  padding: 16px;
  background-color: #d8d8d8;
  border-radius: 4px;
}

.products-config {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
}

.products-config-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
}

.products-config-section-title {
  font-size: 15px;
  font-weight: 600;
}

.products-config-section-subtitle {
  font-size: 13px;
  color: #4b5563;
}


.products-config-section-loading {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  position: relative; /* Necesario para el posicionamiento relativo del borde */
  overflow: hidden; /* Asegura que los pseudo-elementos se mantengan dentro de los bordes redondeados */
}

/* Borde inferior normal (sin animación) */
.products-config-section-loading::after {
  content: "";
  position: absolute;
  bottom: 0; /* Coloca el borde en el borde inferior */
  left: 0;
  width: 100%;
  height: 4px; /* Grosor del borde */
  background: #ddd; /* Color del borde */
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Sombra para dar profundidad */
  z-index: 1;
}

/* Animación pulsante */
.products-config-section-loading::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: -100%;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, rgba(119, 190, 66, 0.8), rgba(119, 190, 66, 0) 50%, rgba(119, 190, 66, 0.8)); /* Efecto de color pulsante */
  animation: pulse 1.5s infinite;
  /* box-shadow: 0 0 20px rgba(76, 175, 80, 0.8); */
  border-radius: 8px;
  z-index: 2;
}

@keyframes pulse {
  0% {
    left: -100%; /* Empieza fuera del borde izquierdo */
  }
  50% {
    left: 0%; /* El gradiente se mueve completamente */
  }
  100% {
    left: 100%; /* Sale por el borde derecho */
  }
}
