.landing-faq-last {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background-color: white;
  color: var(--landing-background-color);
  background: linear-gradient(
    to bottom,
    white 60%,
    var(--landing-highlight-background) 40%
  );
}

.landing-contacto-last {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: auto;
  /* background-color: white; */
  color: var(--landing-background-color);
  background: linear-gradient(
    180deg,
    white 0 90%,
    var(--landing-highlight-background) 90% 100%
  );
}

.landing-faq-menu {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: white;
  padding: 5rem 5% 5rem 5%;
  /* width: 100%; */
  flex: 1;
}

.landing-faq-linea {
  width: 20%;
}

.landing-faq-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: clamp(200px, 900px, 100%);

  border-bottom: 1px solid #d8d8d8;
  padding: 2rem 0px;
  font-family: "Plus Jakarta Sans", sans-serif;
  cursor: pointer;
}

.light-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--landing-background-color);
  background-color: white;
  width: 100%;
  padding: 2rem 5%;
  justify-content: space-between;
}

.landing-faq-desplegables {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0px 16px;
  font-size: 1.05rem;
  width: 100%;
  animation: fade-in-desplegable 500ms forwards ease-in;
  max-height: 0;
  gap: 8px;
  font-family: "Plus Jakarta Sans", sans-serif;
}

@keyframes fade-in-desplegable {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    max-height: 1000px;
  }
}

.landing-faq-desplegable-down {
  cursor: pointer;
  margin-left: auto;
  animation: rotation-down 200ms forwards linear;
}

@keyframes rotation-down {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

.landing-faq-desplegable-right {
  cursor: pointer;
  margin-left: auto;
  animation: rotation-right 200ms forwards linear;
}

@keyframes rotation-right {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.faq-image-fondo {
  position: absolute;
  bottom: -12px;
  right: -12px;
  width: 50%;
  z-index: 1;
}

@media (max-width: 768px) {
  .faq-image-fondo {
    /* bottom: ; */
    width: 90%;
  }

  .landing-faq-linea {
    display: none;
  }
}
